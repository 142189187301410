import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

export const SaveActions = ({ onSave, status, disabled = true, allowed = ['save', 'publish', 'unpublish'] }) => {
    const getOptions = () => {
        let opt = [{ label: t('save', 'Lagre'), status: `${status === 'draft' ? 'draft' : 'published'}` }];

        if (status === 'draft') {
            opt.push({ label: t('save_publish', 'Lagre og publiser'), status: 'published' });
        } else if (allowed.includes('unpublish')) {
            opt.push({ label: t('save_unpublish', 'Lagre og avpubliser'), status: 'draft' });
        }

        return opt;
    };

    const [open, setOpen] = React.useState(false),
        { t } = useTranslation(['dm.article'], { useSuspense: false }),
        anchorRef = React.useRef(null),
        options = getOptions();

    const handleMenuItemClick = index => {
        onSave && onSave(options[index].status);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const renderButton = () => {
        return <Button variant='contained' onClick={() => handleMenuItemClick(0)}>{options[0].label}</Button>
    };

    if (options.length === 1) {
        return renderButton();
    }

    return (
        <Grid container direction="column" alignItems="center">
            <Grid size={{ xs: 12 }}>
                <ButtonGroup ref={anchorRef} disabled={disabled} aria-label="split button">
                    {renderButton()}
                    <Button
                        color="primary"
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu">
                                        {options.map((option, index) => (
                                            <MenuItem
                                                key={option.label}
                                                disabled={index === 2}
                                                onClick={() => handleMenuItemClick(index)}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    );
};
