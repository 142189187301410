import React, { useRef, useState } from 'react';
import Grid from '@mui/material/Grid2';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';


export const ImageSelector = ({ items, item, dmOnly, selected, multipleImg, handleSelect }) => {
    const anchorRef = useRef(null),
        [open, setOpen] = useState(false),
        [url, setUrl] = useState((selected && selected.url) || item.image_url);

    const renderImage = dmsId => {
        const sel = items.filter(obj => obj.image_id === dmsId);

        const handleClick = () => {
            const newUrl = replaceDmsIdInUrl(item.image_url, dmsId);

            dmOnly && setUrl(newUrl);
            handleSelect?.({
                ...item,
                image_url: newUrl,
                image_id: dmsId
            }, !!sel.length);
        };

        return <img key={dmsId} className={sel.length && 'selected'} src={`${replaceDmsIdInUrl(item.image_url, dmsId)}?dimension=57x57-crop`} alt={item.title.substr(0, 9)} onClick={() => handleClick()} />
    };

    const replaceDmsIdInUrl = (url, newId) => {
        return url.replace(url.split('/').splice(-1)[0], newId);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            <div className="image--preview" >
                <img ref={anchorRef} src={`${url}?dimension=167x167`} alt={item.title.substr(0, 9)} onClick={() => multipleImg && setOpen(!open)} />
                {multipleImg && <Typography component="span" className="imgCount">{item.image_ids.length}</Typography>}
            </div>
            {multipleImg && (
                <Popper className="popper" open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                            <Paper className="paper" elevation={4}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <Grid container alignItems="center">
                                        {item.image_ids.map(renderImage)}
                                    </Grid>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            )}
        </>
    );
};
